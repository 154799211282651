/* eslint-disable */

export const columnList = "columnList";

export const GET_NEWSLIST = "GET_NEWSLIST";

export const GET_ARTICLE = "GET_ARTICLE";

export const GET_SESSION = "GET_SESSION";

export const GET_COLLECTION = "GET_COLLECTION";

export const GET_SEARCH = "GET_SEARCH";

export const RETURN_SEARCH = "RETURN_SEARCH";

export const IF_LOADING = "IF_LOADING";

export const ADD_HISTORY = "ADD_HISTORY";

export const REMOVE_HISTORY = "REMOVE_HISTORY";

export const CLEAR_HISTORY = "CLEAR_HISTORY";

export const LOADMORE_SEARCH = "LOADMORE_SEARCH";

export const RETURN_MORE_SEATCH = "RETURN_MORE_SEATCH";

export const ADD_COLLECTION = "ADD_COLLECTION";

export const REMOVE_COLLECTION = "REMOVE_COLLECTION";
// 刷新消息
export const REFRESH_NEWS = "REFRESH_NEWS";
// 刷新消息返回的数量
export const REFRESH_LENGTH = "REFRESH_LENGTH";
// 是否返回刷新
export const RETURN_REFRESH = "RETURN_REFRESH";

export const LOG_TYPE = "LOG_TYPE";

export const SAVE_POSITION = "SAVE_POSITION";

export const GET_NAV = "GET_NAV";

// export const RERURN_IFVIEW = 'RERURN_IFVIEW'

export const GET_OTHER_NAV = "GET_OTHER_NAV";

export const SAVE_USER = "SAVE_USER";
