<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <div>
    <!-- 隐藏组件，登录成功后查询用户其他数据，写入store -->
  </div>
</template>

<script>
/* eslint-disable  */
import debug from "debug";
const _d = debug("@cmpt:userData");

import _ from "lodash";
import wxLoginApi from "../api/wxLoginApi";
import { wxConPost } from "../api/httpApi"; // Data request API

export default {
  name: "userData",
  data() {
    return {
      zdDeptIdArr: [],
      exp: 10 * 1000,
    };
  },
  methods: {
    // 查询用户 站段栏目deptIdArr、我的消息：部门、系统、职名、职务...
    async getUserData() {
      // 判断是否有取过数据
      const userData = this.getLocalStorage("liveUserData", this.exp);

      if (userData && !_.isEmpty(userData)) {
        // 通知数据获取成功
        this.$emit("userData");
        return;
      }
      try {
        if (_.isEmpty(userData) || _.isUndefined(userData)) {
          const result = await wxConPost(
            "/services/gttt-user-mgt/wx/getUserData",
            {}
          );
          _d("---------------------getUserData", result);
          // 数据写入store中
          this.setLocalStorage("liveUserData", JSON.stringify(result));
        }
        // 通知数据获取成功
        this.$emit("userData");
      } catch (e) {
        _d("xxxxxxxxxxx获取用户其他数据Err", e);
      }
    },

    // 封装过期控制代码
    setLocalStorage(key1, value) {
      //获取时间戳
      var curTime = new Date().getTime();
      return localStorage.setItem(
        key1,
        JSON.stringify({
          data: value,
          time: curTime,
        })
      );
    },

    getLocalStorage(key1, exp) {
      const data = localStorage.getItem(key1);
      //转为对象
      const dataObj = data ? JSON.parse(data) : {};
      if (new Date().getTime() - dataObj.time > exp) {
        console.log("已过期", new Date().getTime(), dataObj.time);
        return false;
      } else {
        if (dataObj.data) {
          const dataObjDatatoJson = JSON.parse(dataObj.data);
          return dataObjDatatoJson;
        } else {
          return false;
        }
      }
    },
  },
  created() {
    // 登录成功，查询用户信息
    wxLoginApi.onReady(async () => {
      await this.getUserData();
    });
  },
};
</script>
