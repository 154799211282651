/* eslint-disable  */
/**
 * Created by mq on 17-6-17.
 * 提供前端api，获取用户信息
 */
import { wxGwPost, setToken } from "./httpApi";

const debug = require("debug")("@wxLoginApi");
const _ = require("lodash");

let $location = null;
let $router = null;
let loginSuccessed = false;
let onReadyCallbacks = [];
let userInfo = {};
/**
 * 从url中读取请求字符串
 * @param {string} name
 * @return {*}
 */
function getQueryString(name) {
  const r = $location.href.match(
    // eslint-disable-next-line
    new RegExp(`([\?|&])${name}=([^|&]*)(&|$)`)
  );
  if (r !== null) {
    const str = decodeURI(r[2]);
    const pos = str.indexOf("#/");
    if (pos >= 0) {
      return str.substr(0, pos);
    }
    return str;
  }

  return null;
}
let sceneid = null;

function initUserData() {
  // 记录扫码id
  sceneid = getQueryString("sceneid");
  const usercode = getQueryString("code");
  const path = getQueryString("path");
  const url = $location.href;
  //   debug('==================================path', path, usercode, url);
  // 进行用户认证登录会话
  wxGwPost("/wx/check", {
    url,
    usercode,
    agentid: "1000012", // 扫码登录应用
  })
    .then((result) => {
      debug("========result", result);
      if (result.user) {
        debug("USER LOGIN READY:", result);
        // 成功获取用户信息
        userInfo = result.user;
        // 记录token
        if (result.token) {
          setToken(result.token);
        }
        // 当angular Ready时调用回调函数
        onReadyCallbacks.forEach((cb) => {
          debug("CALL LOGIN Ready()");
          cb();
        });
        loginSuccessed = true;
        onReadyCallbacks = [];

        // 获取用户信息成功，页面跳转
        if ($router && path) {
          $router.replace(path);
        }
      } else {
        debug("NOT USER INFO", result);
        if (result.redirect) {
          debug("11111111111111111111", result.redirect);
          $location.replace(result.redirect);
        }
      }
    })
    .catch((e) => {
      // alert(`0000000000:${path} ${$router}`);
      // res.status(400).json({ err: 'User Not Found' });
      const _redirectUrl = _.get(e, ["response", "data", "redirect"]);
      if (_redirectUrl) {
        // alert(`44444444444444:${_redirectUrl}`);
        // debug('22222222222222222222222222', _redirectUrl);
        $location.replace(_redirectUrl);
      } else {
        // alert(`1111111111:${path} ${$router}`);
        // _d('登录跳转', path)
        // 获取用户信息成功，页面跳转
        if ($router && path) {
          // alert(`333333333333:${path} ${path.indexOf('shareDetailTest')}`);
          // $router.replace(path);
          if (path.indexOf("shareDetail") > -1) {
            $router.replace(path);
          } else {
            // alert(`44444:${path} ${path.indexOf('shareDetailTest') > -1}`);
          }
        }
      }
      // 其他登录失败情况，如登录接口不通
    });
}

function initLocalData() {
  // userInfo = {
  //   userid: "1548992604063aa4bedfbfd16007bff65161d55654c63b6d4053ea5",
  //   name: "于澎",
  //   mobile: "18802083640",
  //   avatar:
  //     "https://p.qlogo.cn/bizmail/4qoGYib0hJWd1QnicPVcpzkUYE2dCNreKicGaWnzTFH737TJibJc2VXVYg/0",
  // };

  // 成功获取用户信息
  // userInfo = {
  //   userid: "13919267170",
  //   name: "刘玉莹",
  //   mobile: "13919267170",
  //   avatar:
  //     "https://p.qlogo.cn/bizmail/4qoGYib0hJWd1QnicPVcpzkUYE2dCNreKicGaWnzTFH737TJibJc2VXVYg/0",
  // };

  userInfo = {
    userid: "1548992604063aa4bedfbfd16007bff65161d55654c63b6d4053ea5",
    name: "于澎",
    mobile: "18802083640",
    avatar:
      "https://p.qlogo.cn/bizmail/4qoGYib0hJWd1QnicPVcpzkUYE2dCNreKicGaWnzTFH737TJibJc2VXVYg/0"
  };
  // userInfo = {
  //   userid: "openid_13919267170",
  //   name: "外部用户",
  //   mobile: "openid_13919267170",
  //   avatar: "",
  // };

  // userInfo = {
  //   userid: "15538381466893e035c4dbbe08fb584d1406199fd2f54f195721fa3",
  //   name: "将夜",
  //   mobile: "18310343563",
  //   avatar:
  //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
  // };

  // userInfo = {
  //   userid: "zhengdongyang",
  //   name: "郑东阳",
  //   mobile: "15810661181",
  //   avatar:
  //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
  // };
  // userInfo = {
  //   userid: "zhangzhihuis",
  //   name: "张志辉",
  //   mobile: "13453976074",
  //   avatar:
  //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
  // };

  const path = getQueryString("path");
  // 进行用户认证登录会话
  wxGwPost("/wx/testCheck", userInfo)
    .then((result) => {
      debug("========result", result);
      setToken(result.token);
      // 当angular Ready时调用回调函数
      onReadyCallbacks.forEach((cb) => {
        debug("CALL LOGIN Ready()");
        cb();
      });
      loginSuccessed = true;
      onReadyCallbacks = [];

      // 获取用户信息成功，页面跳转
      if ($router && path) {
        $router.replace(path);
      }
    })
    .catch((e) => {
      // 当angular Ready时调用回调函数
      onReadyCallbacks.forEach((cb) => {
        debug("CALL LOGIN Ready()");
        cb();
      });
      loginSuccessed = true;
      onReadyCallbacks = [];
    });
}

/**
 * 定时刷新token时间，防止过期
 */
const refreshToken = async () => {
  try {
    const ret = await wxGwPost("/code/check", {});
    if (ret.user) {
      debug("==================refreshToken", ret);
      // 保存userInfo，保存token
      userInfo = ret.user;
      setToken(ret.token);
    }
  } catch (e) {
    debug("refreshToken eeeeee", e);
  }
};
export default {
  refreshToken,
  getSceneId() {
    return sceneid; // 扫码场景id
  },
  getUser() {
    return userInfo;
  },
  init(bLocalTest, router) {
    $location = document.location;
    $router = router;
    // 判断是否有取过数据
    if (!_.isEmpty(this.userInfo)) {
      return;
    }
    if (bLocalTest) {
      // 本地测试，使用模拟数据
      initLocalData();
    } else {
      // 实际环境，获取用户信息
      initUserData();
    }
  },
  onReady(cb) {
    if (loginSuccessed) {
      // 已经成功登录,直接执行
      debug("EXEC LOGIN Ready()");
      setTimeout(cb);
    } else {
      // 没有登录,添加回调
      debug("PUSH LOGIN Ready()");
      onReadyCallbacks.push(cb);
    }
  },
};
