/* eslint-disable  */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import Vuex from "vuex";
Vue.use(Vuex);

import store from "./store";

// import VideoPlayer from "vue-video-player";
// import "video.js/dist/video-js.css"; //videoJs的样式
// import "vue-video-player/src/custom-theme.css"; //vue-video-player的样式
// import "videojs-contrib-hls"; //引入才能播放m3u8文件

import "font-awesome/css/font-awesome.min.css";

// require videojs style
import "video.js/dist/video-js.css";

// 按需引入
// 全局引入
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";

/** 弹幕组件 */
// import { vueBaberrage } from 'temporary-vue-baberrage'
// Vue.use(vueBaberrage)

Vue.use(ViewUI);

// Vue.use(VideoPlayer);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
