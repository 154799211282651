<template>
  <div id="app">
    <wx-login-cmpt></wx-login-cmpt>
    <router-view />
  </div>
</template>

<script>
/* eslint-disable */
import wxLoginCmpt from "./widgets/wxLoginCmpt.vue";
export default {
  name: "App",
  data() {
    return {};
  },
  methods: {},
  mounted: function() {
    require("./css/common.css");
  },
  components: {
    wxLoginCmpt,
  },
  watch: {},
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased; 
  -moz-osx-font-smoothing: grayscale;*/

  /* text-align: center; */
  color: #2c3e50;

  margin: 0;
  padding: 0;
}
</style>
