/* eslint-disable */
import * as type from "./mutations_type.js";
import Vue from "vue";

export default {
  //
  [type.columnList](state, payload) {
    // state.columnList[payload.name] = payload.value
    Vue.set(state.columnList, payload.name, payload.value);
  },
};
