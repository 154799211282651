/* eslint-disable  */
/**
 * 统一Http请求接口
 * 自动带token请求
 */

const WXGW_SERVER = "https://ali.gtrmt.cn:8895"; // 微信网关API
const content_SERVER = "https://mgt.gtrmt.cn"; // 后台管理 部署API
// const content_SERVER = "http://192.168.31.119:9000"; // 后台管理 部署API
// const content_SERVER = "http://192.168.32.226:9000"; // 后台管理 部署API
// const content_SERVER = "http://localhost:9000"; // 后台管理 部署API


// const WXGW_SERVER = "https://zj.gtrmt.cn:8894"; // 微信网关API
// const content_SERVER = "http://192.168.124.7:9000"; // 后台管理  本地调试API

const post = async (url, data) => {
  // 每次取值，防止更新不及时
  const token = `Bearer ${localStorage.token}`;
  const xhr = new XMLHttpRequest();
  xhr.open("POST", url, true);
  xhr.setRequestHeader("content-type", "application/json"); // 设置 HTTP 头，数据指定为 JSON 格式
  xhr.setRequestHeader("Authorization", token);
  return new Promise((resolve, reject) => {
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        if (xhr.status === 200) {
          var result = JSON.parse(xhr.responseText); // 必须从 responseText 读文本数据
          // console.log("1111111", result);
          resolve(result);
        } else {
          console.log("22222222", xhr.readyState, xhr.responseText);
          reject(`HTTP API Fail: ${xhr.status},${xhr.responseText}`);
        }
      }
    };
    xhr.send(JSON.stringify(data));
  });
};
/**
 * 更新token信息
 * @param {*} newToken
 */
export const setToken = (newToken) => {
  localStorage.token = newToken;
};

/**
 * wxGwServer的请求
 */
export const wxGwPost = async (path, data) => {
  const url = `${WXGW_SERVER}${path}`;
  return await post(url, data);
};

/**
 * wxGwServer的请求
 */
export const wxConPost = async (path, data) => {
  const url = `${content_SERVER}${path}`;
  return await post(url, data);
};

export default {
  setToken,
  wxGwPost,
  wxConPost,
};
