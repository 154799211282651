/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations.js";

/******************* 预加载类型*********************************/
Vue.use(Vuex);

const state = {
  columnList: [], // 记录栏目列表，避免重复获取
};

export default new Vuex.Store({ state: state, mutations: mutations });
