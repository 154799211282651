import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/testhwlls",
    name: "testhwlls",
    component: () => import("@/pages/testhwlls.vue"),
  },
  {
    path: "/testPush",
    name: "testPush",
    component: () => import("@/pages/testPush.vue"),
  },
  {
    path: "/testPlay",
    name: "testPlay",
    component: () => import("@/pages/testPlay.vue"),
  },

  {
    path: "/pushRtc",
    name: "pushRtc",
    component: () => import("@/pages/pushRtc.vue"),
  },

  {
    path: "/playRtc",
    name: "playRtc",
    component: () => import("@/pages/playRtc.vue"),
  },

  {
    path: "/search",
    name: "search",
    component: () => import("@/pages/search.vue"),
  },

  {
    path: "/editLive",
    name: "editLive",
    component: () => import("../pages/editLive.vue"),
  },

  {
    path: "/liveDetail",
    name: "liveDetail",
    component: () => import("../pages/liveDetail.vue"),
  },
  {
    path: "/",
    name: "liveList",
    component: () => import("../pages/live/liveList.vue"),
  },
  {
    path: "/myLiveList",
    name: "myLiveList",
    component: () => import("../pages/live/myLiveList.vue"),
  },
  {
    path: "/liveLineDetail",
    name: "liveLineDetail",
    component: () => import("../pages/live/liveLineDetail.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
